import React from "react"
import Layout from "../components/layout/layout"
import InnerWrapper from "../components/layout/innerWrapper"
import SEO from "../components/seo"
import SearchModule from "../components/search/searchModule"

const SearchPage = () => (
  <Layout className="main inner-page-with-banner">
    <SEO
      title={`Walpole Outdoors - Search`}
      description={`The standard all other fence and outdoor structures are measured by.`}
    />
    
    {/* CONTENT GOES HERE */}
    <InnerWrapper>

     <SearchModule />
    </InnerWrapper>

  </Layout>
)

export default SearchPage
